import React, { useState } from 'react';
import './TableView.css';

const TableView = ({ cards, calculatePrice, calculateSteppedPrice, userSettings, onRowClick, currencySymbol, addToCart }) => {
  const [cardStates, setCardStates] = useState(
    cards.map(card => ({
      condition: 'NM',
      printing: card.skus[0]?.printingName || 'Regular',
      trendPrice: card.skus[0]?.lowPrice || 0
    }))
  );
  const [hoverCard, setHoverCard] = useState(null);

  const formatPrice = (price) => {
    if (isNaN(price) || price === null) return 'N/A';
    return `${currencySymbol}${price.toFixed(2)}`;
  };

  const handleConditionChange = (index, newCondition) => {
    setCardStates(prevStates => {
      const newStates = [...prevStates];
      const card = cards[index];
      const currentPrinting = newStates[index].printing;
      const nmPrice = card.skus.find(sku => sku.condAbbr === 'NM' && sku.printingName === currentPrinting)?.lowPrice || card.skus[0]?.lowPrice;
      const newTrendPrice = calculateSteppedPrice(newCondition, nmPrice);
      newStates[index] = { 
        ...newStates[index], 
        condition: newCondition,
        trendPrice: newTrendPrice
      };
      return newStates;
    });
  };

  const handlePrintingChange = (index, newPrinting) => {
    setCardStates(prevStates => {
      const newStates = [...prevStates];
      const card = cards[index];
      const currentCondition = newStates[index].condition;
      const nmPrice = card.skus.find(sku => sku.condAbbr === 'NM' && sku.printingName === newPrinting)?.lowPrice || card.skus[0]?.lowPrice;
      const newTrendPrice = calculateSteppedPrice(currentCondition, nmPrice);
      newStates[index] = { 
        ...newStates[index], 
        printing: newPrinting,
        trendPrice: newTrendPrice
      };
      return newStates;
    });
  };

  const handleAddToCart = (card, isCash, price) => {
    const cardState = cardStates[cards.indexOf(card)];
    // Find the correct SKU based on condition and printing
    const relevantSku = card.skus.find(sku => 
      sku.condAbbr === cardState.condition && 
      sku.printingName === cardState.printing
    );
    addToCart(
      card, 
      cardState.condition, 
      cardState.printing, 
      1, 
      cardState.trendPrice, 
      relevantSku?.skuId, // Use the SKU ID that matches both condition and printing
      isCash, 
      price
    );
  };

  return (
    <div className="table-responsive">
      <table className="card-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Expansion</th>
            <th>Number</th>
            <th>Condition</th>
            <th>Printing</th>
            <th>Trend Price</th>
            <th>Cash Offer</th>
            <th>Credit Offer</th>
          </tr>
        </thead>
        <tbody>
          {cards.map((card, index) => {
            const { condition, printing, trendPrice } = cardStates[index];
            const { cashPrice, creditPrice } = calculatePrice(card, condition, printing);
            
            return (
              <tr 
                key={card._id} 
                onClick={() => onRowClick(card)}
                onMouseEnter={() => setHoverCard(card)}
                onMouseLeave={() => setHoverCard(null)}
              >
                <td>{card.name}</td>
                <td>{card.expansionName}</td>
                <td>{card.number}</td>
                <td>
                  <select 
                    value={condition} 
                    onChange={(e) => handleConditionChange(index, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {['NM', 'LP', 'MP', 'HP', 'DM'].map(cond => (
                      <option key={cond} value={cond}>{cond}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <select 
                    value={printing} 
                    onChange={(e) => handlePrintingChange(index, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {card.skus.map(sku => sku.printingName).filter((v, i, a) => a.indexOf(v) === i).map(print => (
                      <option key={print} value={print}>{print}</option>
                    ))}
                  </select>
                </td>
                <td>{formatPrice(trendPrice)}</td>
                <td>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(card, true, cashPrice);
                    }}
                    className="add-to-cart-btn cash"
                  >
                    {formatPrice(cashPrice)}
                  </button>
                </td>
                <td>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(card, false, creditPrice);
                    }}
                    className="add-to-cart-btn credit"
                  >
                    {formatPrice(creditPrice)}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {hoverCard && (
        <div className="card-hover-image">
          <img src={hoverCard.image} alt={hoverCard.name} />
        </div>
      )}
    </div>
  );
};

export default TableView;
