import React, { useState } from 'react';
import './PinEntryPopup.css';

const PinEntryPopup = ({ isSetup, onSubmit }) => {
  const [pin, setPin] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pin.length === 6) {
      onSubmit(pin);
    }
  };

  return (
    <div className="pin-entry-popup">
      <form onSubmit={handleSubmit}>
        <h2>{isSetup ? 'Set up PIN' : 'Enter PIN'}</h2>
        <input
          type="password"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          maxLength={6}
          pattern="\d{6}"
          required
        />
        <button type="submit">{isSetup ? 'Set PIN' : 'Submit'}</button>
      </form>
    </div>
  );
};

export default PinEntryPopup;
