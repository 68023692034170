import React, { useState, useEffect, useCallback } from 'react';
import './CardPopup.css';

const CardPopup = ({ card, onClose, calculatePrice, userSettings, addToCart }) => {
  const [selectedCondition, setSelectedCondition] = useState('NM');
  const [selectedPrinting, setSelectedPrinting] = useState(card.skus[0]?.printingName || '');
  const [prices, setPrices] = useState({ cashPrice: 0, creditPrice: 0 });
  const [cashPercentage, setCashPercentage] = useState(100);
  const [creditPercentage, setCreditPercentage] = useState(100);
  const [trendPrice, setTrendPrice] = useState(0);
  const [skuPrices, setSkuPrices] = useState({});
  const [isLoadingPrices, setIsLoadingPrices] = useState(true);

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency] || '$';

  useEffect(() => {
    updatePrices();
  }, [selectedCondition, selectedPrinting]);

  useEffect(() => {
    const fetchSkuPrices = async () => {
      setIsLoadingPrices(true);
      try {
        const skuIds = getFilteredSkus().map(sku => sku.skuId);
        const response = await fetch('/api/tcgplayer/prices', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ skuIds }),
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch prices');
        }

        const data = await response.json();
        const pricesMap = {};
        data.forEach(price => {
          pricesMap[price.skuId] = price.lowPrice;
        });
        setSkuPrices(pricesMap);
      } catch (error) {
        console.error('Error fetching prices:', error);
      } finally {
        setIsLoadingPrices(false);
      }
    };

    fetchSkuPrices();
    // Refresh prices every 5 minutes
    const interval = setInterval(fetchSkuPrices, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [card.skus]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  const updatePrices = () => {
    const newTrendPrice = getTrendPrice();
    setTrendPrice(newTrendPrice);
    const { cashPrice, creditPrice } = calculatePrice(card, selectedCondition, selectedPrinting);
    setPrices({ 
      cashPrice: roundToTwoDecimals(cashPrice), 
      creditPrice: roundToTwoDecimals(creditPrice) 
    });
    setCashPercentage(roundToTwoDecimals((cashPrice / newTrendPrice) * 100));
    setCreditPercentage(roundToTwoDecimals((creditPrice / newTrendPrice) * 100));
  };

  const roundToTwoDecimals = (value) => {
    return Number(value.toFixed(2));
  };

  const formatPrice = (price) => {
    return price ? `${currencySymbol}${price.toFixed(2)}` : 'N/A';
  };

  const handleConditionChange = (e) => {
    setSelectedCondition(e.target.value);
  };

  const handlePrintingChange = (e) => {
    setSelectedPrinting(e.target.value);
  };

  const handleCashPriceChange = (e) => {
    const newCashPrice = roundToTwoDecimals(parseFloat(e.target.value));
    setPrices(prev => ({ ...prev, cashPrice: newCashPrice }));
    setCashPercentage(roundToTwoDecimals((newCashPrice / trendPrice) * 100));
  };

  const handleCreditPriceChange = (e) => {
    const newCreditPrice = roundToTwoDecimals(parseFloat(e.target.value));
    setPrices(prev => ({ ...prev, creditPrice: newCreditPrice }));
    setCreditPercentage(roundToTwoDecimals((newCreditPrice / trendPrice) * 100));
  };

  const handleCashPercentageChange = (e) => {
    const newPercentage = roundToTwoDecimals(parseFloat(e.target.value));
    if (!isNaN(newPercentage)) {
      setCashPercentage(newPercentage);
      const newCashPrice = roundToTwoDecimals((trendPrice * newPercentage) / 100);
      setPrices(prev => ({ ...prev, cashPrice: newCashPrice }));
    }
  };

  const handleCreditPercentageChange = (e) => {
    const newPercentage = roundToTwoDecimals(parseFloat(e.target.value));
    if (!isNaN(newPercentage)) {
      setCreditPercentage(newPercentage);
      const newCreditPrice = roundToTwoDecimals((trendPrice * newPercentage) / 100);
      setPrices(prev => ({ ...prev, creditPrice: newCreditPrice }));
    }
  };

  const getTrendPrice = () => {
    const relevantSku = card.skus.find(sku => sku.condAbbr === 'NM' && sku.printingName === selectedPrinting);
    const nmPrice = skuPrices[relevantSku?.skuId] || relevantSku?.lowPrice || 0;
    
    let adjustedPrice;
    switch (selectedCondition) {
      case 'NM': adjustedPrice = nmPrice; break;
      case 'LP': adjustedPrice = nmPrice * 0.8; break;
      case 'MP': adjustedPrice = nmPrice * 0.64; break;
      case 'HP': adjustedPrice = nmPrice * 0.512; break;
      case 'DM': adjustedPrice = nmPrice * 0.4096; break;
      default: adjustedPrice = nmPrice;
    }
    
    return roundToTwoDecimals(adjustedPrice);
  };

  const handleAddToCart = (isCash) => {
    const relevantSku = card.skus.find(sku => sku.condAbbr === selectedCondition && sku.printingName === selectedPrinting);
    addToCart(
      card,
      selectedCondition,
      selectedPrinting,
      1,
      trendPrice,
      relevantSku?.skuId,
      isCash,
      isCash ? prices.cashPrice : prices.creditPrice
    );
  };

  const handleOverlayClick = useCallback((e) => {
    if (e.target.classList.contains('card-popup-overlay')) {
      onClose();
    }
  }, [onClose]);

  const getCurrentSkuId = () => {
    const relevantSku = card.skus.find(sku => 
      sku.condAbbr === selectedCondition && 
      sku.printingName === selectedPrinting
    );
    return relevantSku?.skuId || 'N/A';
  };

  const getFilteredSkus = () => {
    return card.skus.filter(sku => 
      sku.langAbbr === 'EN'
    ).sort((a, b) => {
      // Sort by printing first
      if (a.printingName !== b.printingName) {
        return a.printingName.localeCompare(b.printingName);
      }
      // Then by condition
      const condOrder = { NM: 1, LP: 2, MP: 3, HP: 4, DM: 5 };
      return condOrder[a.condAbbr] - condOrder[b.condAbbr];
    });
  };

  return (
    <div className="card-popup-overlay" onClick={handleOverlayClick}>
      <div className="card-popup">
        <button className="close-button" onClick={onClose}>
          <span className="close-icon">×</span>
        </button>
        <div className="card-popup-content">
          <div className="card-image-container">
            <img src={card.image} alt={card.name} className="card-image" />
            <div className="sku-list">
              <h4>Available SKUs {isLoadingPrices && '(Loading prices...)'}</h4>
              <table>
                <thead>
                  <tr>
                    <th>SKU ID</th>
                    <th>Print</th>
                    <th>Cond</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {getFilteredSkus().map(sku => (
                    <tr key={sku.skuId}>
                      <td>{sku.skuId}</td>
                      <td>{sku.printingName}</td>
                      <td>{sku.condAbbr}</td>
                      <td>{formatPrice(skuPrices[sku.skuId] || sku.lowPrice)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-info">
            <h2 className="card-name">{card.name}</h2>
            <div className="card-details">
              <p><strong>Expansion:</strong> {card.expansionName}</p>
              <p><strong>Number:</strong> {card.number || 'N/A'}</p>
              <p><strong>Selected SKU ID:</strong> {getCurrentSkuId()}</p>
              <p><strong>Trend Price:</strong> {formatPrice(trendPrice)}</p>
            </div>
            <div className="card-options">
              <select value={selectedCondition} onChange={handleConditionChange}>
                {card.skus.map(sku => sku.condAbbr).filter((v, i, a) => a.indexOf(v) === i).map(condition => (
                  <option key={condition} value={condition}>{condition}</option>
                ))}
              </select>
              <select value={selectedPrinting} onChange={handlePrintingChange}>
                {card.skus.map(sku => sku.printingName).filter((v, i, a) => a.indexOf(v) === i).map(printing => (
                  <option key={printing} value={printing}>{printing}</option>
                ))}
              </select>
            </div>
            <div className="price-inputs">
              <div className="price-input">
                <label>Cash Offer:</label>
                <input type="number" value={prices.cashPrice.toFixed(2)} onChange={handleCashPriceChange} step="0.01" min="0" />
                <input type="range" value={cashPercentage} onChange={handleCashPercentageChange} min="0" max="100" step="0.01" />
                <span>{cashPercentage.toFixed(2)}%</span>
              </div>
              <div className="price-input">
                <label>Credit Offer:</label>
                <input type="number" value={prices.creditPrice.toFixed(2)} onChange={handleCreditPriceChange} step="0.01" min="0" />
                <input type="range" value={creditPercentage} onChange={handleCreditPercentageChange} min="0" max="100" step="0.01" />
                <span>{creditPercentage.toFixed(2)}%</span>
              </div>
            </div>
            <div className="card-actions">
              <button onClick={() => handleAddToCart(true)} className="add-to-cart-btn cash">
                Add to Cart (Cash)
              </button>
              <button onClick={() => handleAddToCart(false)} className="add-to-cart-btn credit">
                Add to Cart (Credit)
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPopup;
